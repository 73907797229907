<!--能源-KPI--KPI警报管理---->
<template>
  <div id="eneKPITaskSetupIndex" v-loading="loading" :element-loading-text="$t('alarm.a0')">
    <!-- KPI警报管理  -->
    <my-header-title>{{ $t("energy.enedata598") }}</my-header-title> 
    <div class="header">
      <div class="header-left">
        
        <div class="header-condition">
            <span> {{$t('energy.enedata344')}}：</span>
            <a-select v-model="pointInfo" style="width: 200px;">
              <a-select-option :title="item.name"
                  v-for="(item, index) in pointList"
                  :key="index"
                  :value="item.key"
                >
                  {{ item.name }}
                </a-select-option>
            </a-select>
        </div>
      </div>
      <div>
        <a-button type="primary" style="width: 120px"  @click="search()">{{$t('energy.enedata006')}} </a-button>
      </div>
    </div>
    <div class="main" ref="main">
         <!--  -->
         <a-table 
         :row-selection="rowSelection"
         :columns="columns" 
         :data-source="data"
         :row-key="record => record.id"  
         :pagination="pagination"  
         :scroll="tscroll"
         bordered size="middle" >
                <template slot="chaozuo" slot-scope="text, record ">
                    <div class="editable-row-operations">
                        <a-switch v-model="record.executeEnable" @click="() => editRecord(record)" :checked-children="$t('energy.enedata593')" :un-checked-children="$t('energy.enedata594')" />
                    </div>
                </template>
        </a-table>
    </div>
    <!-- <div class="footer">
        <div>
            <a-button type="primary" style="width: 120px" @click="batchEditRecord" :disabled="selectedRowKeys.length==0">
              {{$t('energy.ok')}}
            </a-button>
          
        </div>
        
    </div> -->
    

  </div>
</template>



<script>
import { getKPIAutoTaskList ,updateKPIAutoTask   ,batchUpdateKPIAutoTask} from "../../../api/energy";
import headerTitle from "../../../components/Title/headerTitle";
import moment from "moment";
export default {
  name: "eneKPITaskSetupIndex",
  data() {
    
    return {
      loading: false,
      data:[],
      pointList:[],
      pointInfo:'',
     

      columns:[
         {title: 'NO'		 
          ,dataIndex: "NO",
            customRender: (text, record, index) =>
              `${
                (this.pagination.defaultCurrent - 1) * this.pagination.pageSize +
                index +
                1
              }`,
            width: 60,},
         
          {title: this.$t('energy.setting.reportGr.pointName')		 , dataIndex: 'pointName'		  		      },       
          {title: this.$t('energy.enedata589')		               , dataIndex: 'executeEnable'		   ,width:100  ,  
           customRender: (text, record, index) =>
              `${
                (record.executeEnable?this.$t('energy.enedata593'):this.$t('energy.enedata594')) //'停止''启用'
              }`,
          },
          {title: this.$t('energy.enedata586')		               , dataIndex: 'executeEnableDate'	,width:150      },
          {title: this.$t('energy.enedata587')                      		 , dataIndex: 'executeDate'	,width:150      },
          {
              title: this.$t('energy.enedata151'),
              dataIndex: "chaozuo",
              scopedSlots: { customRender: "chaozuo" },
              width: 200,
              fixed: "right",
          },
      ],
     
      pagination:{
          total: 0, //数据总数
          pageSize: 100, //每页中显示10条数据
          showTotal: (total) => {
            let msg = this.$t("energy.enedata195");
            msg = msg.replace('${total}',total);
            return msg;
              // `共 ${total} 条数据`
          }, //分页中显示总的数据
          showQuickJumper: true, //是否可以快速跳转至某页
          defaultCurrent: 1, //默认当前页面数
          hideOnSinglePage: false, //只有一页时是否隐藏分页：默认值false
          onShowSizeChange: (current, pageSize) => {
            this.pagination.defaultCurrent = current;
            this.pagination.pageSize = pageSize;
            this.loadKPIAutoTaskList(1,current,pageSize);
          },
          // 改变每页数量时更新显示
          onChange: (current, size) => {
            this.pagination.defaultCurrent = current;
            this.pagination.pageSize = size;
            this.loadKPIAutoTaskList(1,current,size);
          },
      },
      
      selectedRowKeys:[],
      tscroll:{y:240}
    };
  },
  computed: {
    rowSelection() {
      const { selectedRowKeys } = this;
      return {
        selectedRowKeys,
        onSelect: this.onSelectChange2,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        type:'checkbox'
      };
    },
  },
  activated() {
    this.loadKPIAutoTaskList(0,0,0);
  },
  mounted() { 
    // 调用表格高度计算方法
    this.tableSize();
    // 根据窗口变化计算表格高度
    window.addEventListener("resize", this.tableSize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.tableSize);
  },
  methods:{
    // 表格高度计算
    tableSize() {
        setTimeout(() => {
            let height = this.$refs.main.clientHeight;
            let width = this.$refs.main.clientWidth;
            let tableHeight = height - 100;
            this.tscroll.y = tableHeight;
            this.tscroll.x = width-50;
            console.log(width);
            console.log(tableHeight);
        }, 0);
        
    },
    editRecord(record){
       this.$confirm({
            title: this.$t('energy.enedata184'),
            centered: true,
            onOk: () => {
                let ids = [];
                ids.push(record.id);
                this.update(ids,record.executeEnable);
            },
            onCancel:() => {
                record.executeEnable = !record.executeEnable;
            },
        });
      // console.log('editRecord',record.executeEnable);
    },
    update(ids,executeEnable){
        let  params =  {
            action:1,
            clientId: this.$store.getters.clientId,
            siteId: this.$route.query.id,
            sitegrId: this.$store.getters.sitegrId,
            tenantId: 0,
            ids:ids,
            autoEnable:executeEnable
        };
        this.loading = true;
        batchUpdateKPIAutoTask(params)
        .then((res) => {
            this.loading = false;
            console.log("BatchUpdateKPIAutoTask Result",res);
            if(res.errorCode == '03'){
                this.$message.success(res.msg);
                this.search();
            } else {
                this.$message.error(res.msg);
            }
        })
        .catch((err) => {
            this.loading = false;
            console.log(err);
        });
    },
    loadKPIAutoTaskList(action,pageNum,pageSize){
        let params = null;
        if(action===1){
            let pointId = 0;
            let pointType = 0;
            if(this.pointInfo!=''){
                let points = this.pointInfo.split("@@");
                pointId   = points && points.length>0? points[0]:0;
                pointType = points && points.length>1? points[1]:0;
            }
            params =  {
                action:action,
                clientId: this.$store.getters.clientId,
                siteId: this.$route.query.id,
                sitegrId: this.$store.getters.sitegrId,
                tenantId: 0,
                pointId:pointId,
                pointType:pointType,
                pageNum:pageNum,
                pageSize:pageSize

            };
        }else{
          params =  {
              action:0,
              clientId: this.$store.getters.clientId,
              siteId: this.$route.query.id,
              sitegrId: this.$store.getters.sitegrId,
              tenantId: 0             
          };
        }
        console.log("InitPage Params",params);
        this.loading = true;
        getKPIAutoTaskList(params)
        .then((res) => {
            console.log("LoadData Result",res);
            if(res.errorCode == '00'){
              if(action==0){
                  this.pointList = [{key: "", name: this.$t("energy.enedata472")},...res.data.pointList];
              }else if(action==1){
                  this.data = res.data.list;
                  this.pagination.total = res.data.total;
                  this.tableSize();
              }
                
            } 
            this.loading = false;
        })
        .catch((err) => {
            this.loading = false;
            console.log(err);
        });
    },
    search(){
        this.loadKPIAutoTaskList(1,1,this.pagination.pageSize);
    },
    onSelectChange(selectedRowKeys, selectedRows) {
        console.log("selectedRowKeys changeddd: ", selectedRowKeys);
        console.log("selectedRowKeys changeddc: ", selectedRows);
        this.selectedRowKeys = selectedRowKeys;
        this.selectedRows = selectedRows;
    },
     onSelectChange2(record, selected, selectedRows, nativeEvent) {
        this.selectedRows    = selectedRows;
        this.selectedRowKeys = [];
        this.selectedRows.forEach(element => {
            this.selectedRowKeys.push(element.id);
        });
    },
  },
  components: {
    'my-header-title':headerTitle,
  },
};
</script>

<style scoped>

#eneKPITaskSetupIndex {
  width: 100%;
  height: 100%;
  padding: 0 20px;
  position:relative;
  font-family:apple-system, BlinkMacSystemFont, 'Segoe UI','PingFangSC-Regular', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'
}
.header-title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 18px;
  padding: 10px 0;
  border-bottom: 1px solid rgba(12, 12, 12, 0.1);
}
.header-title::before {
  content: "";
  display: inline-block;
  width: 2px;
  height: 22px;
  margin-right: 20px;
  background-color: #7682ce;
}
.header {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px 0px 20px;
  overflow: hidden;
}
.header-left {
  display: flex;
}
.header-condition {
  display: flex;
  align-items: center;
  font-size: 14px;
  white-space: nowrap;
  margin-right: 25px;
}
.main{
    width: 100%;
    height: calc(100% - 120px);
    box-shadow: 0px 3px 8px #e7e6e6;
}
.footer {
  position:absolute;
  bottom: 5px;
  display: flex;
  justify-content:space-between;
  padding: 10px 10px;
}

input {
  width: 14%;
}
button {
  width: 80px;
}
.footer button {
  margin-left: 15px;
}
</style>



